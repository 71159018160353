import { useCallback, useRef } from 'react'
import { SanityPageLoader } from '../../../components/SanityPageLoader'
import { AllSitePagesQuery, PageTemplateQuery } from '../../../graphql/gatsby'
import { SiteSettings } from '../../../hooks/useSiteSettings'
import type { SanityPageContext } from '../types'

export type UseStaticSanityPagesOptions = Pick<
  AllSitePagesQuery,
  | 'allSanityPage'
  | 'allSanityTerms'
  | 'comingSoon1x'
  | 'comingSoon1xWebp'
  | 'comingSoon2x'
  | 'comingSoon2xWebp'
  | 'comingSoon3x'
  | 'comingSoon3xWebp'
> & {
  sanitySiteSettings: SiteSettings
}

export interface UseStaticSanityPages {
  pages: SanityPage[]
}

export interface SanityPage {
  type: 'sanity'
  path: string
  component: typeof SanityPageLoader
  context: SanityPageContext
  data: PageTemplateQuery
}

export const useStaticSanityPages = ({
  sanitySiteSettings,
  allSanityPage,
  ...sharedSanityPageData
}: UseStaticSanityPagesOptions): UseStaticSanityPages => {
  const pagesRef = useRef<SanityPage[]>([])

  const siteSettings = sanitySiteSettings
  if (!siteSettings) {
    throw new Error('Site settings not found')
  }
  const frontPageId = siteSettings.dispensaryFrontpage?.id || siteSettings.frontpage?.id
  if (!frontPageId) {
    throw new Error('Neither front page nor dispensary front page specified')
  }

  const createPage = useCallback((page: SanityPage): void => {
    pagesRef.current.push(page)
  }, [])

  const pageEdges = (allSanityPage || {}).edges || []

  pageEdges.forEach((edge) => {
    const { id, slug } = edge.node
    const currentSlug = slug?.current || ''

    const context = {
      id,
      slug: currentSlug,
    }

    const data: PageTemplateQuery = {
      ...sharedSanityPageData,
      page: edge.node,
    }

    if (id === frontPageId) {
      createPage({
        type: 'sanity',
        path: '/',
        component: SanityPageLoader,
        context,
        data,
      })
    }
    createPage({
      type: 'sanity',
      path: `/${currentSlug}`,
      component: SanityPageLoader,
      context,
      data,
    })
  })

  return { pages: pagesRef.current }
}
