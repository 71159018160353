import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import classNames from 'classnames'
import React, { FC, useEffect } from 'react'
import styled from 'styled-components'

import { SiteRegisterType } from '../../graphql/gatsby'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import Head from '../../layouts/Head'
import { isRegisterType, useAccountsUrl } from '../../lib/accounts'
import { RegisterType } from '../../lib/accounts/types'
import Layout from '../global/Layout'
import SiteLogo from '../global/SiteLogo'
import Spinner from '../Spinner'

import dfhPatternGray from '../../img/hero/dfh-pattern-gray.svg'
import iconAtom from '../../img/registration/atom.svg'

interface RegistrationPageProps {
  className?: string
}

const toRegisterType = (type: SiteRegisterType): RegisterType | undefined => {
  switch (type) {
    case SiteRegisterType.PATIENT:
      return 'patient'
    case SiteRegisterType.PATIENT_ZIP_CODE:
      return 'patient-zip-code'
    case SiteRegisterType.PRACTITIONER:
      return 'practitioner'
    case SiteRegisterType.PRIVATE_LABEL_PRACTITIONER:
      return 'private-label-practitioner'
    case SiteRegisterType.PRIVATE_LABEL_UNLICENSED:
      return 'private-label-unlicensed'
    case SiteRegisterType.STUDENT:
      return 'student'
    default:
      return undefined
  }
}

const RegistrationPage: FC<RegistrationPageProps> = ({ className }) => {
  const { redirectToAccount } = useAccountsUrl()
  const { registerTypeDefault } = useSiteMetadata() ?? {}

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const fragment = window.location.hash.replace('#', '')
    redirectToAccount({
      page: 'register',
      registerType: isRegisterType(fragment)
        ? fragment
        : registerTypeDefault
        ? toRegisterType(registerTypeDefault)
        : undefined,
    })
  }, [redirectToAccount, registerTypeDefault])

  return (
    <>
      <Head
        title={'Register for Your DFH Account - Get Superior Natural Supplements'}
        description={
          'Register with DFH as a practitioner or patient and start your journey to a healthier lifestyle with high quality supplements, vitamins, resources, and more.'
        }
      />
      <GlobalStyle includeTypography />
      <Layout showHeader={false}>
        <div className={className}>
          <div className="section-content-left-side">
            <div>
              <SiteLogo className="logo" />
            </div>
            <h1 className={classNames('page-title', 'gothamBold')}>
              The health care professional&apos;s trusted source since 1989
            </h1>
            <div className="bottom-section">
              <div>
                <img className="bottom-icon" src={iconAtom} alt="Designs for Health" />
              </div>
              <div className={classNames('bottom-title', 'gothamBold')}>
                Science Based Formula Design
              </div>
              <div className="bottom-text"></div>
              <div className="bar">
                <div className={classNames('bar-indicator', 'bar-active')} />
                <div className="bar-indicator" />
                <div className="bar-indicator" />
                <div className="bar-indicator" />
              </div>
            </div>
          </div>
          <div className="section-content-right-side">
            <Spinner loading />
          </div>
        </div>
      </Layout>
    </>
  )
}

RegistrationPage.defaultProps = {
  className: '',
}

const StyledRegistrationPage = styled(RegistrationPage)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  backgroundColor: theme.colors.grayscale.white,
  minHeight: '80vh',
  '@media(min-width: 992px)': {
    minHeight: '800px',
  },
  '.section-content-left-side': {
    flex: '0 0 37.5%',
    maxWidth: '37.5%',
    backgroundColor: '#09203C', // TODO: Review how to add new colors
    padding: '40px',
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundImage: `url(${dfhPatternGray})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1439px 480px',
    backgroundPosition: '-856px 265px',
    '@media(min-width: 992px)': {
      display: 'flex',
    },
  },
  '.section-content-right-side': {
    padding: '20px',
    width: '100%',
    '@media(min-width: 992px)': {
      flex: '0 0 62.5%',
      maxWidth: '62.5%',
      padding: '40px',
    },
  },
  '.logo': {
    display: 'block',
    width: '193px',
    marginBottom: '60px',
  },
  '.page-title': {
    fontSize: '36px',
    lineHeight: '42.84px',
    color: theme.colors.grayscale.white,
    margin: 0,
  },
  '.bottom-section': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  '.bottom-icon': {
    width: '30px',
    marginBottom: '15px',
  },
  '.bottom-title': {
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.colors.grayscale.white,
    marginBottom: '10px',
  },
  '.bottom-text': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.colors.grayscale.white,
    marginBottom: '15px',
  },
  '.bar': {
    display: 'flex',
    width: '100%',
    gap: '5px',
  },
  '.bar-indicator': {
    width: '25%',
    height: '2px',
    backgroundColor: theme.colors.grayscale.white,
    opacity: '0.34',
  },
  '.bar-active': {
    opacity: 1,
  },
}))

export { StyledRegistrationPage as RegistrationPage }
