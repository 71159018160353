import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import Head from '../layouts/Head'
import FullPageError from './FullPageError'
import Layout from './global/Layout'
import Link from './global/Link'

export interface ErrorPageProps {
  children: React.ReactNode
  title?: string
  showHomeLink?: boolean
}

const HomeLink = styled.small`
  ${({ theme }) => css`
    a {
      color: ${theme.colors.grayscale.white};
    }
  `}
`

export const ErrorPage: FC<ErrorPageProps> = ({ title, showHomeLink, children }) => {
  const pageTitle = title || 'Error'
  return (
    <>
      <Head title={pageTitle} />
      <GlobalStyle includeTypography />
      <Layout>
        <FullPageError>
          {children}
          {showHomeLink && (
            <HomeLink>
              <Link to={'/products'}>Return to Store</Link>
            </HomeLink>
          )}
        </FullPageError>
      </Layout>
    </>
  )
}
