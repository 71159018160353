import { format, parseISO } from 'date-fns'
import { dispensaryBasePathPrefix } from '../config'
import { DispensarySuperseded } from '../types'

export const getSupersededDispensaryMessage = ({
  name,
  expirationDate,
  supersededByName,
  supersededBySlug,
}: DispensarySuperseded) => {
  const dispensaryName = supersededByName
    ? `"${supersededByName}"`
    : name
    ? `"${name}"`
    : "Practitioner's"
  const dispensaryPath = `/${dispensaryBasePathPrefix}/${supersededBySlug}`
  if (!expirationDate) {
    return `You are currently not browsing the main ${dispensaryName} dispensary. Click here to visit the main dispensary at ${dispensaryPath}`
  }
  const expirationDateObj = parseISO(expirationDate)
  const expirationFormatted = format(expirationDateObj, 'PP')
  const isExpired = new Date() >= expirationDateObj
  if (isExpired) {
    return `This ${dispensaryName} dispensary has expired on ${expirationFormatted}. Click here to visit the main dispensary at ${dispensaryPath}`
  }
  return `This ${dispensaryName} dispensary will expire by ${expirationFormatted}. Click here to visit the main dispensary at ${dispensaryPath}`
}
