import { useCallback, useEffect, useMemo } from 'react'

import { getDispensarySupersededToastActions } from '../../../components/dispensaries/toasts/DispensarySupersededToastActions'
import { usePageUrl } from '../../pages'
import { usePersistentToast } from '../../toasts/hooks/usePersistentToast'
import { DispensarySuperseded } from '../types'
import { replaceCurrentDispensarySlug } from '../utils'
import { getSupersededDispensaryMessage } from '../utils/superseded'
import { useDispensaryContext } from './useDispensaryContext'

const toastId = 'dispensary-superseded'

export const useDispensarySupersededToast = () => {
  const { navigate } = usePageUrl()
  const dispensaryContext = useDispensaryContext()

  const dispensarySuperseded = useMemo<DispensarySuperseded | null>(() => {
    if (!dispensaryContext) {
      return null
    }
    const { supersededBySlug, ...rest } = dispensaryContext
    if (!supersededBySlug) {
      return null
    }
    return { supersededBySlug, ...rest }
  }, [dispensaryContext])

  const isDispensarySuperseded = !!dispensarySuperseded

  const {
    shouldShowToast,
    shouldHideToast,
    showToast,
    hideToast,
    dismissToast,
  } = usePersistentToast({
    toastId,
    condition: () => isDispensarySuperseded,
  })

  const message = useMemo(() => {
    if (!dispensarySuperseded) {
      return null
    }
    return getSupersededDispensaryMessage(dispensarySuperseded)
  }, [dispensarySuperseded])

  const navigateToMainDispensary = useCallback(() => {
    if (!dispensarySuperseded) {
      return
    }
    const mainDispensaryPath = replaceCurrentDispensarySlug(dispensarySuperseded.supersededBySlug)
    navigate(mainDispensaryPath, { forceBase: true })
  }, [dispensarySuperseded, navigate])

  useEffect(() => {
    if (!shouldShowToast || !dispensarySuperseded) {
      return
    }
    showToast(message, {
      variant: 'warning',
      onClick: () => navigateToMainDispensary(),
      action: getDispensarySupersededToastActions({
        dismissToast,
        navigateToMainDispensary,
      }),
    })
  }, [
    shouldShowToast,
    showToast,
    dismissToast,
    message,
    dispensarySuperseded,
    navigateToMainDispensary,
  ])

  useEffect(() => {
    if (shouldHideToast) {
      hideToast()
    }
  }, [shouldHideToast, hideToast])
}
