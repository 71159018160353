import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { BlockContentProps } from '@sanity/block-content-to-react'
import React, { FC } from 'react'
import styled from 'styled-components'

import Head from '../layouts/Head'
import ErrorMessagePortableText from './ErrorMessagePortableText'
import Layout from './global/Layout'

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  padding: 50px 0;
`

const StyledErrorMessagePortableText = styled(ErrorMessagePortableText)`
  max-width: 41em; // 660px
  color: ${({ theme }) => theme.colors.grayscale.white};
  text-align: center;
`

export interface ErrorPortableTextPageProps {
  blocks: BlockContentProps['blocks']
  title?: string
}

export const ErrorPortableTextPage: FC<ErrorPortableTextPageProps> = ({ blocks, title }) => {
  const pageTitle = title || 'Error'
  return (
    <>
      <Head title={pageTitle} />
      <GlobalStyle />
      <Layout>
        <PageContainer>
          <StyledErrorMessagePortableText blocks={blocks} />
        </PageContainer>
      </Layout>
    </>
  )
}
