import type { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { ComponentProps, ComponentType, ReactElement, useEffect, useMemo } from 'react'

import { DispensaryAuth0Provider } from '../../context/DispensaryAuth0Provider'
import { DispensaryContextProvider } from '../../context/DispensaryContextProvider'
import useLogAndCaptureError from '../../hooks/useLogAndCaptureError'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import {
  getDispensaryFromData,
  removeDispensaryPrefixAndSlug,
  useDispensary,
  useInvalidUrlDispensary,
} from '../../lib/dispensaries'
import { setDispensary } from '../../lib/dispensaries/state'
import { ErrorPage } from '../ErrorPage'
import { LoadingPage, LoadingPageProps } from '../LoadingPage'
import { DispensaryRouteContents } from './DispensaryRouteContents'
import { InvalidDispensaryModal } from './InvalidDispensaryModal'

export type DispensaryRouteProps<
  TComponent extends DispensaryWrappedComponent
> = RouteComponentProps<{ slug: string }> &
  DispensaryWrappedComponentProps<TComponent> & {
    component: TComponent
    loadingPageComponent?: React.ComponentType<LoadingPageProps>
    privateRoute?: boolean
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DispensaryWrappedComponent = ComponentType<any>
type DispensaryWrappedComponentProps<
  TComponent extends DispensaryWrappedComponent
> = ComponentProps<TComponent>

const uriRegex = /^\/u\/([^/?#]+)/

export const DispensaryRoute = <TComponent extends DispensaryWrappedComponent>({
  slug: slugProp,
  component,
  loadingPageComponent = LoadingPage,
  privateRoute,
  // exclude routing props
  path,
  default: defaultProp,
  location,
  navigate,
  uri,
  // gather component props to forward
  ...componentProps
}: DispensaryRouteProps<TComponent>): ReactElement => {
  const { featureFlags } = useSiteMetadata() ?? {}
  // resolve slug from uri prop if not available (e.g. default route does not receive slug)
  const slug = slugProp ?? uri.match(uriRegex)?.[1] ?? ''
  const { loading, data, error, called } = useDispensary({ slug })
  const {
    loading: loadingInvalidUrlDispensary,
    invalidUrlDispensaryForSlug,
  } = useInvalidUrlDispensary()

  const loadingPageElement = useMemo(() => {
    return React.createElement(loadingPageComponent, { title: 'Virtual Dispensary' })
  }, [loadingPageComponent])

  useLogAndCaptureError(error)

  const { disableInvalidDispensaries } = featureFlags ?? {}

  // reduce useMemo dependency list by determining whether we should call
  // `invalidUrlDispensaryForSlug` if `data` not available
  const invalidUrlDispensaryFactory =
    called && !loading && !disableInvalidDispensaries && !loadingInvalidUrlDispensary
      ? invalidUrlDispensaryForSlug
      : undefined

  const dispensary = useMemo(
    () => (data ? getDispensaryFromData(data) : invalidUrlDispensaryFactory?.(slug)),
    [data, invalidUrlDispensaryFactory, slug]
  )

  useEffect(() => {
    if (loading) {
      return
    }
    setDispensary(dispensary)
  }, [dispensary, loading])

  if (!called || loading) {
    return loadingPageElement
  }

  if (error) {
    const errorMessage = typeof error === 'string' ? error : error.message
    console.error('Dispensary route error', error)
    return <ErrorPage showHomeLink>{errorMessage}</ErrorPage>
  }

  if (!dispensary) {
    return (
      <>
        {loadingPageElement}
        {disableInvalidDispensaries && <InvalidDispensaryModal isOpen />}
      </>
    )
  }

  return (
    <DispensaryContextProvider dispensary={dispensary}>
      <DispensaryAuth0Provider>
        <DispensaryRouteContents
          loadingPageComponent={loadingPageComponent}
          path={removeDispensaryPrefixAndSlug(uri)}
          privateRoute={privateRoute}
        >
          {React.createElement(component, componentProps)}
        </DispensaryRouteContents>
      </DispensaryAuth0Provider>
    </DispensaryContextProvider>
  )
}
