import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { CategoryDocument } from '../../../graphql/magento'
import { CategoryPage } from './CategoryPage'
import { CategoryPageContext } from '../../../lib/pages'
import { LoadingPage } from '../../LoadingPage'
import { ErrorPage } from '../../ErrorPage'

export interface CategoryPageLoaderProps {
  pageContext: CategoryPageContext
}

export const CategoryPageLoader: FC<CategoryPageLoaderProps> = ({ pageContext }) => {
  const { categoryId } = pageContext
  const { data, loading, error, called } = useQuery(CategoryDocument, {
    variables: { id: `${categoryId}` },
  })

  if (!called || loading) {
    return <LoadingPage />
  }

  if (error || !data) {
    console.error('CategoryPageTemplate error', error)
    return (
      <ErrorPage showHomeLink>
        Error loading category. Please check the URL and try again.
      </ErrorPage>
    )
  }

  const categoryList = data?.categoryList
  if (!categoryList || categoryList.length === 0 || !categoryList[0]) {
    console.error('Empty category query response', data)
    return (
      <ErrorPage showHomeLink>Category not found. Please check the URL and try again.</ErrorPage>
    )
  }

  const category = categoryList[0]
  return <CategoryPage category={category} />
}
