import { Auth0Context, Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import React, { useMemo } from 'react'

import { mergeLoginWithRedirectOptions } from '../lib/auth'
import {
  mergeDispensaryLoginOptions,
  mergeDispensaryLogoutOptions,
  useDispensaryContext,
} from '../lib/dispensaries'
import { usePageUrl } from '../lib/pages'

export const DispensaryAuth0Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    loginWithRedirect: loginWithRedirectAuth0,
    logout: logoutAuth0,
    ...auth0ContextRest
  } = useAuth0()
  const dispensary = useDispensaryContext()
  const { getPageUrl } = usePageUrl()

  const contextValue = useMemo(
    (): Auth0ContextInterface => ({
      ...auth0ContextRest,
      loginWithRedirect: (options) => {
        const mergedOptions = mergeLoginWithRedirectOptions(options)
        const loginOptions = dispensary
          ? mergeDispensaryLoginOptions(dispensary.slug, mergedOptions)
          : mergedOptions

        return loginWithRedirectAuth0({
          'ext-signup-url': getPageUrl('/register'),
          ...loginOptions,
        })
      },
      logout: (options) => {
        const logoutOptions = dispensary
          ? mergeDispensaryLogoutOptions(dispensary.slug, options)
          : options
        return logoutAuth0(logoutOptions)
      },
    }),
    [auth0ContextRest, dispensary, getPageUrl, loginWithRedirectAuth0, logoutAuth0]
  )

  return <Auth0Context.Provider value={contextValue}>{children}</Auth0Context.Provider>
}
