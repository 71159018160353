import { useRef, useCallback, useMemo } from 'react'
import { ResearchPageLoader } from '../../../components/research/ResearchPageLoader'
import { AllSitePagesQuery, ResearchPageTemplateQuery } from '../../../graphql/gatsby'
import type { ResearchPageContext } from '../types'

export interface UseStaticResearchPages {
  pages: ResearchPage[]
}

export interface ResearchPage {
  type: 'research'
  path: string
  component: typeof ResearchPageLoader
  context: ResearchPageContext
  data: ResearchPageTemplateQuery
}

interface SitePage {
  path: string
  context: {
    id: string
    slug: string
  }
}

export const useStaticResearchPages = ({
  allSiteResearchPage,
  allSanityResearch,
}: Pick<
  AllSitePagesQuery,
  'allSiteResearchPage' | 'allSanityResearch'
>): UseStaticResearchPages => {
  const pagesRef = useRef<ResearchPage[]>([])

  const createPage = useCallback((page: ResearchPage): void => {
    pagesRef.current.push(page)
  }, [])

  const sitePages = useMemo(
    () =>
      ((allSiteResearchPage || {}).edges || []).reduce((acc: SitePage[], { node }) => {
        if (!node) return acc
        const { pageContext, path } = node
        const { id, slug } = pageContext || {}
        if (!id || !slug) return acc
        acc.push({
          path,
          context: { id, slug },
        })
        return acc
      }, []),
    [allSiteResearchPage]
  )
  const researchPageEdges = (allSanityResearch || {}).edges || []

  researchPageEdges.forEach((edge) => {
    const { id } = edge.node

    const sitePage = sitePages.find(({ context }) => context?.id === id)
    if (!sitePage) return

    createPage({
      type: 'research',
      path: sitePage.path,
      component: ResearchPageLoader,
      context: sitePage.context,
      data: {
        research: edge.node,
      },
    })
  })

  return { pages: pagesRef.current }
}
