import React, { FC } from 'react'
import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import Head from '../../../layouts/Head'
import Layout from '../../global/Layout'
import ProductsContainer from './ProductsContainer'
import type { CategoryInterface } from '../../../graphql/magento'

export interface CategoryPageProps {
  category: CategoryInterface
}

export const CategoryPage: FC<CategoryPageProps> = ({ category }) => {
  return (
    <>
      <Head title={category.name ?? 'Products'} />
      <GlobalStyle includeTypography />
      <Layout>
        <ProductsContainer pageContext={{ category }} />
      </Layout>
    </>
  )
}
