import { useCallback, useRef } from 'react'
import { ProductPageLoader } from '../../../components/shop/pdp/ProductPageLoader'
import { AllSitePagesQuery, ProductPageTemplateQuery } from '../../../graphql/gatsby'
import type { ProductPageContext } from '../types'

export type UseStaticProductPagesOptions = Pick<
  AllSitePagesQuery,
  'allSanityParentProduct' | 'allSiteProductPage'
>

export interface UseStaticProductPages {
  pages: ProductPage[]
}

export interface ProductPage {
  type: 'product'
  path: string
  component: typeof ProductPageLoader
  context: ProductPageContext
  data: ProductPageTemplateQuery
}

export const useStaticProductPages = ({
  allSanityParentProduct,
  allSiteProductPage,
}: UseStaticProductPagesOptions): UseStaticProductPages => {
  const pagesRef = useRef<ProductPage[]>([])

  const createPage = useCallback((page: ProductPage): void => {
    pagesRef.current.push(page)
  }, [])

  allSiteProductPage.edges.forEach((edge) => {
    const { node } = edge
    const context = node.pageContext as ProductPageContext
    const { sku } = context
    const sanityParentProduct = allSanityParentProduct.edges.find(
      ({ node: { sku: nodeSku } }) => nodeSku === sku
    )?.node
    const data: ProductPageTemplateQuery = {
      sanityParentProduct,
    }

    createPage({
      type: 'product',
      path: node.path,
      component: ProductPageLoader,
      context,
      data,
    })
  })

  return { pages: pagesRef.current }
}
