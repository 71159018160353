import { ButtonLink } from '@designsforhealth/dfh-react-components'
import BlockContent, {
  BlockContentProps,
  ListRendererProps,
  MarkRendererProps,
} from '@sanity/block-content-to-react'
import React from 'react'
import styled, { css } from 'styled-components'

import { headingFontSize } from '../theme/helpers'

const StyledButtonLink = styled(ButtonLink)(
  ({ theme }) =>
    css`
      color: ${theme.colors.component.link};
      font-family: inherit;
      // reduce line-height slightly for bottom border
      line-height: 1.1;
      border-bottom: 1px dotted ${theme.colors.component.link};

      &:hover {
        border-bottom-style: solid;
      }
    `
)

const RefreshPageLink: React.FC<MarkRendererProps> = ({ children }) => {
  const refreshPage = (): void => {
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
  }

  return <StyledButtonLink onClick={refreshPage}>{children}</StyledButtonLink>
}

const sharedListStyles = css<{ $level: number }>`
  margin-left: ${({ $level }) => ($level === 1 ? 0 : '0.75rem')};
`

const bulletTypes = ['square', 'disc', 'circle']
const BulletList = styled.ul<{ $level: number }>`
  ${sharedListStyles};
  list-style-type: ${({ $level }) => bulletTypes[$level % bulletTypes.length]};
`

const numberTypes = ['lower-roman', 'decimal', 'lower-alpha']
const NumberedList = styled.ol<{ $level: number }>`
  ${sharedListStyles};
  list-style-type: ${({ $level }) => numberTypes[$level % numberTypes.length]};
`

const ListRenderer: React.FC<ListRendererProps> = ({ children, type, level }) =>
  type === 'bullet' ? (
    <BulletList $level={level}>{children}</BulletList>
  ) : (
    <NumberedList $level={level}>{children}</NumberedList>
  )

const serializers: BlockContentProps['serializers'] = {
  list: ListRenderer,
  marks: {
    refreshPageLink: RefreshPageLink,
  },
}

export interface BodyPortableTextProps {
  className?: string
  blocks: BlockContentProps['blocks']
}

const ErrorMessagePortableText: React.VFC<BodyPortableTextProps> = ({ className, blocks }) => (
  <div className={className} data-cy="error-message">
    <BlockContent blocks={blocks} serializers={serializers} />
  </div>
)

const StyledErrorMessagePortableText = styled(ErrorMessagePortableText)`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol {
    line-height: 1.5;
  }

  h1 {
    font-size: ${({ theme }) => headingFontSize({ size: 4, theme })};
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    font-size: ${({ theme }) => headingFontSize({ size: 3, theme })};
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  h3 {
    font-size: ${({ theme }) => headingFontSize({ size: 2, theme })};
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h4 {
    font-size: ${({ theme }) => headingFontSize({ size: 1, theme })};
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }

  p,
  ul,
  ol {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ul,
  ol {
    margin-top: 0.5em;
    padding-inline-start: 1.15em;
  }

  li {
    margin-top: 0.5em;
  }

  a[href] {
    color: ${({ theme }) => theme.colors.component.link};
    // avoid wrapping anchor text when possible
    display: inline-block;
    text-decoration: none;
    // reduce line-height slightly for bottom border
    line-height: 1.1;
    border-bottom: 1px dotted ${({ theme }) => theme.colors.component.link};

    &:hover {
      border-bottom-style: solid;
    }
  }
`

export default StyledErrorMessagePortableText
