import React from 'react'
import * as Styled from './styled'
import Head from '../../../layouts/Head'

interface InvalidDispensaryModalProps {
  isOpen: boolean
}

export const InvalidDispensaryModal: React.VFC<InvalidDispensaryModalProps> = ({ isOpen }) => {
  return (
    <Styled.StyledModal isOpen={isOpen} allowScroll={false}>
      <Head title="Dispensary Not Found" />
      <Styled.Wrapper>
        <Styled.Title>Dispensary not found.</Styled.Title>
        <Styled.MagnifyingGlassImage />
        <Styled.Text>
          We&apos;re sorry! We can&apos;t seem to find that Practitioner Virtual Dispensary. Please
          check the URL and try again.
        </Styled.Text>
        <Styled.SmallText>
          If you are still having trouble, please contact your health care practitioner to confirm
          their Virtual Dispensary URL. Alternatively, please call our Customer Experience team at{' '}
          <Styled.PhoneNumber href="tel:18606236314">860-623-6314</Styled.PhoneNumber> for
          assistance.
        </Styled.SmallText>
      </Styled.Wrapper>
      <Styled.Wrapper />
    </Styled.StyledModal>
  )
}
