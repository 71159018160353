import { Button, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { SnackbarAction, SnackbarKey } from 'notistack'
import React, { FC, MouseEvent, useCallback } from 'react'
import styled from 'styled-components'

export interface DispensarySupersededToastActionsProps {
  snackbarKey: SnackbarKey
  dismissToast: () => void
  navigateToMainDispensary: () => void
}

const CloseButton = styled(IconButton)`
  margin-left: 10px;

  &.MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`

export const getDispensarySupersededToastActions = (
  props: Omit<DispensarySupersededToastActionsProps, 'snackbarKey'>
): SnackbarAction => {
  const Actions = (snackbarKey: SnackbarKey) => {
    return <DispensarySupersededToast snackbarKey={snackbarKey} {...props} />
  }
  return Actions
}

export const DispensarySupersededToast: FC<DispensarySupersededToastActionsProps> = ({
  dismissToast,
  navigateToMainDispensary,
}) => {
  const onClickClose = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      dismissToast()
    },
    [dismissToast]
  )

  const onClickNavigate = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      navigateToMainDispensary()
    },
    [navigateToMainDispensary]
  )

  return (
    <>
      <Button color="inherit" size="small" onClick={onClickNavigate}>
        Go to Dispensary
      </Button>
      <CloseButton size="small" aria-label="close" color="inherit" onClick={onClickClose}>
        <CloseIcon fontSize="small" />
      </CloseButton>
    </>
  )
}
