import styled from 'styled-components'
import Modal from 'styled-react-modal'

import Background from '../../../img/layered-waves-haikei.svg'
import MagnifyingGlassSvg from '../../../img/magnifying-glass.inline.svg'

export const StyledModal = Modal.styled({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  textAlign: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '30px',
  width: '100%',
  height: '100%',
  maxHeight: '1690px',
  position: 'absolute',
  top: '60px',
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  '@media (min-width: 768px)': {
    top: '80px',
  },
  '@media (min-width: 992px)': {
    maxWidth: '992px',
    top: '104px',
    height: '80vh',
  },

  '@media (min-width: 1200px)': {
    maxWidth: '1200px',
  },
})

export const MagnifyingGlassImage = styled(MagnifyingGlassSvg)`
  display: block;
  color: ${({ theme }) => theme.colors.brand.orange};
  padding: 16px 0 32px;
  width: 72px;
  @media (min-width: 768px) {
    width: 136px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-family: 'Gotham', Helvetica, Arial, 'sans-serif';
  font-weight: bold;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.42rem;
  @media (min-width: 640px) {
    font-size: 36px;
  }
`

export const Text = styled.p`
  font-size: 1rem;
  margin: 0;
  @media (min-width: 640px) {
    font-size: 1.5rem;
  }
`

export const PhoneNumber = styled.a`
  color: ${({ theme }) => theme.colors.brand.red};
`

export const SmallText = styled(Text)`
  font-size: 0.8rem;
  margin-top: revert;
  @media (min-width: 640px) {
    font-size: 1rem;
  }
`
