import { RouteComponentProps } from '@reach/router'
import React, { useEffect, useMemo } from 'react'

import { addDispensaryBasePathToPath } from '../../lib/dispensaries'
import { LoadingPage, LoadingPageProps } from '../LoadingPage'

export type DispensaryRedirectProps = RouteComponentProps<{ slug: string }> & {
  loadingPageComponent?: React.ComponentType<LoadingPageProps>
  to: string
}

export const DispensaryRedirect: React.VFC<DispensaryRedirectProps> = ({
  loadingPageComponent = LoadingPage,
  slug,
  navigate,
  to,
}) => {
  useEffect(() => {
    if (!slug || !navigate) {
      return
    }
    const targetPath = addDispensaryBasePathToPath(slug, to)
    navigate(targetPath, { replace: true })
  }, [navigate, slug, to])

  return useMemo(() => {
    return React.createElement(loadingPageComponent, { title: 'Loading...' })
  }, [loadingPageComponent])
}
