import type { AllSitePagesQuery } from '../../../graphql/gatsby'
import useSiteSettings from '../../../hooks/useSiteSettings'
import { CategoryPage, useStaticCategoryPages } from './useStaticCategoryPages'
import { ProductPage, useStaticProductPages } from './useStaticProductPages'
import { ProductRedirectPage, useStaticProductRedirectPages } from './useStaticProductRedirectPages'
import { ResearchPage, useStaticResearchPages } from './useStaticResearchPages'
import { SanityPage, useStaticSanityPages } from './useStaticSanityPages'

export interface UseAllStaticPages {
  productPages: ProductPage[]
  productRedirectPages: ProductRedirectPage[]
  categoryPages: CategoryPage[]
  researchPages: ResearchPage[]
  sanityPages: SanityPage[]
}

export const useAllStaticPages = ({
  allSiteProductPage,
  allSiteProductRedirectPage,
  allSiteCategoryPage,
  allSiteResearchPage,
  allSanityResearch,
  allSanityPage,
  allSanityParentProduct,
  allSanityTerms,
  comingSoon1x,
  comingSoon1xWebp,
  comingSoon2x,
  comingSoon2xWebp,
  comingSoon3x,
  comingSoon3xWebp,
}: AllSitePagesQuery): UseAllStaticPages => {
  const sanitySiteSettings = useSiteSettings()
  const { pages: productPages } = useStaticProductPages({
    allSanityParentProduct,
    allSiteProductPage,
  })
  const { pages: productRedirectPages } = useStaticProductRedirectPages({
    allSiteProductRedirectPage,
  })
  const { pages: categoryPages } = useStaticCategoryPages({ allSiteCategoryPage })
  const { pages: researchPages } = useStaticResearchPages({
    allSiteResearchPage,
    allSanityResearch,
  })
  const { pages: sanityPages } = useStaticSanityPages({
    sanitySiteSettings,
    allSanityPage,
    allSanityTerms,
    comingSoon1x,
    comingSoon1xWebp,
    comingSoon2x,
    comingSoon2xWebp,
    comingSoon3x,
    comingSoon3xWebp,
  })
  return { productPages, productRedirectPages, categoryPages, researchPages, sanityPages }
}
