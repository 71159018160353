import { graphql } from 'gatsby'
import React from 'react'

import useSiteSettings from '../../hooks/useSiteSettings'
import { ErrorPage } from '../ErrorPage'
import { ErrorPortableTextPage } from '../ErrorPortableTextPage'

export const DispensaryUpdateCartErrorPage: React.FC = () => {
  const {
    _rawDispensaryUpdateCartErrorMessage: dispensaryUpdateCartErrorMessage,
  } = useSiteSettings()

  return dispensaryUpdateCartErrorMessage ? (
    <ErrorPortableTextPage blocks={dispensaryUpdateCartErrorMessage} title="Error" />
  ) : (
    <ErrorPage title="Error">
      Please refresh the page or try again. An error has occurred while updating your session.
    </ErrorPage>
  )
}

export const dispensaryUpdateCartErrorMessageFragment = graphql`
  fragment dispensaryUpdateCartErrorMessage on SanitySiteSettings {
    _rawDispensaryUpdateCartErrorMessage
  }
`
