import { useRef, useCallback } from 'react'
import { CategoryPageLoader } from '../../../components/shop/products/CategoryPageLoader'
import { AllSitePagesQuery } from '../../../graphql/gatsby'
import type { CategoryPageContext } from '../types'

export interface UseStaticCategoryPages {
  pages: CategoryPage[]
}

export interface CategoryPage {
  type: 'category'
  path: string
  component: typeof CategoryPageLoader
  context: CategoryPageContext
}

export const useStaticCategoryPages = ({
  allSiteCategoryPage,
}: Pick<AllSitePagesQuery, 'allSiteCategoryPage'>): UseStaticCategoryPages => {
  const pagesRef = useRef<CategoryPage[]>([])

  const createPage = useCallback((page: CategoryPage): void => {
    pagesRef.current.push(page)
  }, [])

  allSiteCategoryPage.edges.forEach((edge) => {
    const { node } = edge
    createPage({
      type: 'category',
      path: node.path,
      component: CategoryPageLoader,
      context: {
        categoryId: node.pageContext?.categoryId as number, // query filter ensures urlKey won't be nullable,
      },
    })
  })

  return { pages: pagesRef.current }
}
