import { Router } from '@gatsbyjs/reach-router'
import { graphql, PageProps } from 'gatsby'
import React, { FC, useEffect } from 'react'
import styled from 'styled-components'

import { CheckoutLoadingPage } from '../../components/buy/checkout/CheckoutLoadingPage'
import { CheckoutRoutes } from '../../components/buy/checkout/CheckoutRoutes'
import { DispensaryRedirect } from '../../components/dispensaries/DispensaryRedirect'
import { DispensaryRoute } from '../../components/dispensaries/DispensaryRoute'
import { AssetsPage } from '../../components/library/assets/AssetsPage'
import { CasiSessionPage } from '../../components/library/casi-sessions/CasiSessionPage'
import { SocialMediaResourcePage } from '../../components/library/social-media-resources/SocialMediaResourcePage'
import { WebinarPage } from '../../components/library/webinars/WebinarPage'
import NotFoundPage from '../../components/NotFoundPage'
import { OnBoardingPage } from '../../components/onboarding/OnBoardingPage'
import { RegistrationPage } from '../../components/registration/RegistrationPage'
import { Account } from '../../components/shop/account/Account'
import { LoginPage } from '../../components/shop/account/LoginPage'
import { ProductsPage } from '../../components/shop/products/ProductsPage'
import { SearchResultsPage } from '../../components/shop/products/SearchResultsPage'
import type { AllSitePagesQuery } from '../../graphql/gatsby'
import { getDispensaryBasePath } from '../../lib/dispensaries'
import { latestDispensary$, setDispensary } from '../../lib/dispensaries/state'
import { useAllStaticPages } from '../../lib/pages/hooks/useAllStaticPages'
import { SanitySiteContext } from '../../lib/sanity/context'
import { SanityContext } from '../../lib/sanity/context-based-content'
import { dispensaryLogoCdn$ } from '../../lib/sanity-dispensaries/query'
import { latestDispensarySlug$ } from '../../lib/sanity-dispensaries/state'

const StyledRouter = styled(Router)`
  // expand to fill focus wrapper added by Gatsby
  // ref: https://github.com/gatsbyjs/gatsby/issues/7310
  width: 100%;
  height: 100%;
`

const DispensaryRoutes: FC<PageProps<AllSitePagesQuery>> = ({ data }) => {
  const { rewardLevelsImage, sanityCheckoutMarketingContent } = data
  const {
    productPages,
    productRedirectPages,
    categoryPages,
    researchPages,
    sanityPages,
  } = useAllStaticPages(data)
  useEffect(() => {
    const subscriptions = [
      // hold subscription to dispensary (mirrors context value)
      latestDispensary$.subscribe(),
      // hold subscription to slug for use in Sanity dispensary queries
      latestDispensarySlug$.subscribe(),
      dispensaryLogoCdn$.subscribe(),
    ]
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
      // clear dispensary context on unmount. This is important when using browser navigation to exit
      // a dispensary and return to the main site.
      setDispensary(undefined)
    }
  }, [])

  return (
    <SanitySiteContext.Provider value={SanityContext.Dispensary}>
      <StyledRouter basepath={`${getDispensaryBasePath('/:slug')}`}>
        <DispensaryRoute
          path="/checkout/*"
          component={CheckoutRoutes}
          loadingPageComponent={CheckoutLoadingPage}
          checkoutMarketingContent={sanityCheckoutMarketingContent}
        />
        <DispensaryRoute path="/products" component={ProductsPage} />
        <DispensaryRoute
          path="/account/*"
          component={Account}
          privateRoute
          rewardLevelsImage={rewardLevelsImage}
        />
        <DispensaryRoute path="/login" component={LoginPage} />
        <DispensaryRoute path="/onboarding" component={OnBoardingPage} privateRoute />
        <DispensaryRoute path="/register" component={RegistrationPage} />
        <DispensaryRoute path="/search-results" component={SearchResultsPage} />
        <DispensaryRoute
          path="/research-education/library/assets/*"
          component={AssetsPage}
          privateRoute
        />
        <DispensaryRoute
          path="/research-education/library/casi-session/*"
          component={CasiSessionPage}
        />
        <DispensaryRoute
          path="/research-education/library/social-media-resource/*"
          component={SocialMediaResourcePage}
        />
        <DispensaryRoute
          path="/research-education/library/webinar/*"
          component={WebinarPage}
          privateRoute
        />
        {categoryPages.map((page) => (
          <DispensaryRoute
            key={page.path}
            path={encodeURI(page.path)}
            component={page.component}
            pageContext={page.context}
          />
        ))}
        {productPages.map((page) => (
          <DispensaryRoute
            key={page.path}
            path={encodeURI(page.path)}
            component={page.component}
            pageContext={page.context}
            data={page.data}
          />
        ))}
        {productRedirectPages.map((page) => (
          <DispensaryRedirect
            key={page.path}
            path={encodeURI(page.path)}
            to={encodeURI(`/products/${page.context.targetUrlKey}`)}
          />
        ))}
        {researchPages.map((page) => (
          <DispensaryRoute
            key={page.path}
            path={encodeURI(page.path)}
            component={page.component}
            pageContext={page.context}
            data={page.data}
          />
        ))}
        {sanityPages.map((page) => (
          <DispensaryRoute
            key={page.path}
            path={encodeURI(page.path)}
            component={page.component}
            pageContext={page.context}
            data={page.data}
          />
        ))}
        <DispensaryRoute default component={NotFoundPage} />
      </StyledRouter>
    </SanitySiteContext.Provider>
  )
}

export const query = graphql`
  fragment sitePageEdge on SitePageEdge {
    node {
      id
      path
      component
      pageContext
    }
  }

  query allSitePages {
    # product pages
    allSiteProductPage: allSitePage(
      filter: { component: { glob: "**/src/templates/products/product.*" } }
    ) {
      edges {
        ...sitePageEdge
      }
    }
    # product redirect pages
    allSiteProductRedirectPage: allSitePage(
      filter: { component: { glob: "**/src/templates/products/product-redirect.*" } }
    ) {
      edges {
        ...sitePageEdge
      }
    }
    # category pages
    allSiteCategoryPage: allSitePage(
      filter: { component: { glob: "**/src/templates/products/category.*" } }
    ) {
      edges {
        ...sitePageEdge
      }
    }
    # research pages
    allSiteResearchPage: allSitePage(
      filter: { component: { glob: "**/src/templates/research.*" } }
    ) {
      edges {
        ...sitePageEdge
      }
    }
    allSanityResearch(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          mainImage {
            alt
            caption
          }
          title
          keywords
          publishedAt
          roleBasedAccessControl {
            enabled
            roles
          }
          showSocialLinks
          _rawHeaderImage(resolveReferences: { maxDepth: 5 })
          _rawHeader(resolveReferences: { maxDepth: 10 })
          _rawBody(resolveReferences: { maxDepth: 10 })
          _rawExcerpt(resolveReferences: { maxDepth: 10 })
          _rawMainImage(resolveReferences: { maxDepth: 5 })
          _rawOpenGraphImage(resolveReferences: { maxDepth: 5 })
        }
      }
    }
    # sanity pages
    allSanityPage(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          title
          description
          keywords
          slug {
            current
          }
          _rawOpenGraphImage(resolveReferences: { maxDepth: 5 })
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
    # product pages
    allSanityParentProduct(filter: { sku: { ne: null }, title: { ne: null } }) {
      edges {
        node {
          # reduced set of product data
          sku
          title
          relatedProducts {
            sku
          }
        }
      }
    }
    ...allSanityTerms
    ...comingSoonImage
    # account queries
    ...rewardsLevelImage
    ...sanityCheckoutMarketingContent
  }
`

export default DispensaryRoutes
