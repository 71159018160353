import React, { FC, useEffect } from 'react'
import useLogAndCaptureError from '../../hooks/useLogAndCaptureError'
import { useAuth } from '../../lib/auth'
import { useDispensaryContext } from '../../lib/dispensaries'
import { normalizePath } from '../../lib/pages'
import { logAndCaptureException } from '../../utils/errorTools'
import { ErrorPage } from '../ErrorPage'

export interface DispensaryPrivateRouteProps {
  returnToSlug?: string
}

export const DispensaryPrivateRoute: FC<DispensaryPrivateRouteProps> = ({
  children,
  returnToSlug,
}) => {
  const { error, isAuthenticated, isLoading, loginWithRedirect } = useAuth()
  const dispensary = useDispensaryContext()

  useEffect(() => {
    if (isAuthenticated || isLoading || !dispensary) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: normalizePath(returnToSlug),
        },
      })
    })()
  }, [isAuthenticated, isLoading, loginWithRedirect, returnToSlug, dispensary])

  useLogAndCaptureError(error)

  if (!dispensary) {
    logAndCaptureException(
      new Error('Dispensary Private Route requested but no Dispensary context available')
    )
    return <ErrorPage showHomeLink>Invalid Page Requested</ErrorPage>
  }

  // return isAuthenticated ? <>{children}</> : <LoadingPage />
  return <>{children}</>
}
