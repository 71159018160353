import React, { FC } from 'react'

import { useDispensaryUpdateSession } from '../../lib/dispensaries'
import { useDispensarySupersededToast } from '../../lib/dispensaries/hooks/useDispensarySupersededToast'
import { LoadingPage, LoadingPageProps } from '../LoadingPage'
import { DispensaryPrivateRoute } from './DispensaryPrivateRoute'
import { DispensaryUpdateCartErrorPage } from './DispensaryUpdateCartErrorPage'

export interface DispensaryRouteContentsProps {
  loadingPageComponent?: React.ComponentType<LoadingPageProps>
  path: string
  privateRoute?: boolean
}

export const DispensaryRouteContents: FC<DispensaryRouteContentsProps> = ({
  children,
  loadingPageComponent = LoadingPage,
  path,
  privateRoute,
}) => {
  const { called, error } = useDispensaryUpdateSession()
  useDispensarySupersededToast()

  if (!called) {
    return React.createElement(loadingPageComponent, { title: 'Loading...' })
  }
  if (error) {
    return <DispensaryUpdateCartErrorPage />
  }

  return privateRoute ? (
    <DispensaryPrivateRoute returnToSlug={path}>{children}</DispensaryPrivateRoute>
  ) : (
    <>{children}</>
  )
}
