import React, { FC } from 'react'
import { Dispensary, DispensaryContext } from '../lib/dispensaries'

export interface DispensaryContextProviderProps {
  dispensary: Dispensary
}

export const DispensaryContextProvider: FC<DispensaryContextProviderProps> = ({
  children,
  dispensary,
}) => {
  return <DispensaryContext.Provider value={dispensary}>{children}</DispensaryContext.Provider>
}
