import { useCallback, useRef } from 'react'
import { AllSitePagesQuery } from '../../../graphql/gatsby'
import type { ProductRedirectPageContext } from '../types'

export interface UseStaticProductRedirectPages {
  pages: ProductRedirectPage[]
}

export interface ProductRedirectPage {
  type: 'product-redirect'
  path: string
  context: ProductRedirectPageContext
}

export const useStaticProductRedirectPages = ({
  allSiteProductRedirectPage,
}: Pick<AllSitePagesQuery, 'allSiteProductRedirectPage'>): UseStaticProductRedirectPages => {
  const pagesRef = useRef<ProductRedirectPage[]>([])

  const createPage = useCallback((page: ProductRedirectPage): void => {
    pagesRef.current.push(page)
  }, [])

  allSiteProductRedirectPage.edges.forEach((edge) => {
    const { node } = edge
    createPage({
      type: 'product-redirect',
      path: node.path,
      context: {
        targetUrlKey: node.pageContext?.targetUrlKey as string, // query filter ensures urlKey won't be nullable
      },
    })
  })

  return { pages: pagesRef.current }
}
